import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({ imageID, mSize }) => {
   const mediasData = useStaticQuery(graphql`
      query {
         medias: allWordpressWpMedia {
            edges {
               node {
                  wordpress_id
                  alt_text
                  localFile {
                     publicURL
                     childImageSharp {
                        large: fluid(
                           maxWidth: 1680
                           srcSetBreakpoints: [200, 340, 520, 890]
                        ) {
                           ...GatsbyImageSharpFluid
                        }
                        medium: fluid(
                           maxWidth: 1280
                           srcSetBreakpoints: [200, 340, 520, 890]
                        ) {
                           ...GatsbyImageSharpFluid
                        }
                        small: fluid(
                           maxWidth: 980
                           srcSetBreakpoints: [200, 340, 520, 890]
                        ) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
         }
      }
   `)

   if (imageID !== null) {
      let currentImage = mediasData.medias.edges.find(media => {
         return media.node.wordpress_id === imageID ? media : false
      })

      if (currentImage) {
         if (mSize === "small") {
            return false && currentImage.node.localFile.childImageSharp ? (
               <Img
                  fluid={currentImage.node.localFile.childImageSharp.small}
                  critical={true}
                  loading="eager"
                  alt={currentImage.node.localFile.alt_text}
               />
            ) : (
               <img
                  src={currentImage.node.localFile.publicURL}
                  alt={currentImage.node.localFile.alt_text}
               />
            )
         } else if (mSize === "medium") {
            return false && currentImage.node.localFile.childImageSharp ? (
               <Img
                  fluid={currentImage.node.localFile.childImageSharp.medium}
                  critical={true}
                  loading="eager"
                  alt={currentImage.node.localFile.alt_text}
               />
            ) : (
               <img
                  src={currentImage.node.localFile.publicURL}
                  alt={currentImage.node.localFile.alt_text}
               />
            )
         } else {
            return false && currentImage.node.localFile.childImageSharp ? (
               <Img
                  fluid={currentImage.node.localFile.childImageSharp.large}
                  critical={true}
                  loading="eager"
                  alt={currentImage.node.localFile.alt_text}
               />
            ) : (
               <img
                  src={currentImage.node.localFile.publicURL}
                  alt={currentImage.node.localFile.alt_text}
               />
            )
         }
      } else {
         return false
      }
   } else {
      return false
   }
}

export default Image
