import React from 'react'

const Schedule = (oHours) => {

   return (
      <div className="block-hours__schedule">
         <table className="page--contact__schedule">
            <tbody>

            {oHours.oHours.map((hour, index) => {
               let scheduleTime

               if(hour.midi.open.replace(':', '') > 0 && hour.soir.open.replace(':', '') > 0) {
                  scheduleTime = hour.midi.open+' - '+hour.midi.close+'<span></span>'+hour.soir.open+' - '+hour.soir.close
               } else if(hour.midi.open.replace(':', '') > 0) {
                  scheduleTime = hour.midi.open+' - '+hour.midi.close
               } else if(hour.soir.open.replace(':', '') > 0) {
                  scheduleTime = hour.soir.open+' - '+hour.soir.close
               } else {
                  scheduleTime = 'Fermé'
               }

               return(
                  <tr key={`ScheduleItem-${index}`}>
                     <td>{hour.translatedDay}</td>
                     <td dangerouslySetInnerHTML={{__html: scheduleTime}} />
                  </tr>
               )
               
            })}
            </tbody>
         </table>
      </div>
   )
}

export default Schedule