import React from "react"
import { Link } from "gatsby"
import Schedule from "../../objects/Schedule"
const axios = require("axios")

class BlockHours extends React.Component {
   constructor(props) {
      super(props)

      this.state = {
         hours: [],
         blockOpacity: 0,
         blockInfos: {
            icon: "error",
            shortText: "Nous sommes fermés",
            longText: "Nous sommes fermés.",
         },
      }
   }

   componentWillMount() {
      axios({
         method: "get",
         url:
            "https://graph.facebook.com/501446976573727?fields=hours&access_token=EAAKAEG4WLtQBAD6HLNVqczkz3gGumUJPPtk5mInfvZAWLNBlEIt6uxIZCo1yeB4fCGjjZA3f1AOZBn1p7RmYTQThuWPXGQQpUfGjLoeM29kXJhzFHWr0XaRHs1cjDAKK0r2dyMzUVqkPlsvi6J5p1GJDsyIGEu8U3ogmE73MZBBaNc70h71KDGZBgYRG6AHn71bCMoC82vayUYLUx8Px3d29G8mZC8vPVoZD",
      })
         .then(response => {
            this.parseHours(response.data.hours)
            this.checkCurrentTime()
         })
         .catch(error => {
            console.log(error)
         })
   }

   parseHours = allDay => {
      /* eslint no-eval: 0 */
      let availaibleDay = [
         {
            prefix: "mon",
            translated: "Lundi",
         },
         {
            prefix: "tue",
            translated: "Mardi",
         },
         {
            prefix: "wed",
            translated: "Mercredi",
         },
         {
            prefix: "thu",
            translated: "Jeudi",
         },
         {
            prefix: "fri",
            translated: "Vendredi",
         },
         {
            prefix: "sat",
            translated: "Samedi",
         },
         {
            prefix: "sun",
            translated: "Dimanche",
         },
      ]

      let oHours = []

      availaibleDay.forEach(day => {
         let hoursObject = {
            translatedDay: day.translated,
            midi: {
               open: eval("allDay." + day.prefix + "_1_open")
                  ? eval("allDay." + day.prefix + "_1_open")
                  : "00:00",
               close: eval("allDay." + day.prefix + "_1_close")
                  ? eval("allDay." + day.prefix + "_1_close")
                  : "00:00",
            },
            soir: {
               open: eval("allDay." + day.prefix + "_2_open")
                  ? eval("allDay." + day.prefix + "_2_open")
                  : "00:00",
               close: eval("allDay." + day.prefix + "_2_close")
                  ? eval("allDay." + day.prefix + "_2_close")
                  : "00:00",
            },
         }

         oHours.push(hoursObject)
      })

      this.setState({
         hours: oHours,
         blockOpacity: 1,
      })
   }

   checkCurrentTime = () => {
      const parseH = str => {
         return str.replace(":", "")
      }

      let d = new Date()

      let currentDay = d.getDay() - 1

      let currentHours =
         ("0" + d.getHours()).slice(-2) + ("0" + d.getMinutes()).slice(-2)

      let hoursOfTheDay = this.state.hours[currentDay]

      if (
         parseH(hoursOfTheDay.midi.open) < currentHours &&
         parseH(hoursOfTheDay.midi.close) > currentHours
      ) {
         this.setState({
            blockInfos: {
               icon: "valid",
               shortText: "Nous sommes ouverts",
               longText:
                  "Nous sommes ouverts de " +
                  hoursOfTheDay.midi.open +
                  " à " +
                  hoursOfTheDay.midi.close,
            },
         })
      } else if (
         parseH(hoursOfTheDay.soir.open) < currentHours &&
         parseH(hoursOfTheDay.soir.close) > currentHours
      ) {
         this.setState({
            blockInfos: {
               icon: "valid",
               shortText: "Nous sommes ouverts",
               longText:
                  "Nous sommes ouverts de " +
                  hoursOfTheDay.soir.open +
                  " à " +
                  hoursOfTheDay.soir.close,
            },
         })
      }
   }

   render() {
      return (
         <div
            id="blockHours"
            className="block-hours"
            style={{ opacity: this.state.blockOpacity }}
         >
            <i
               id="iconBlockHours"
               className={`icon icon--${this.state.blockInfos.icon}`}
            ></i>{" "}
            <span aria-hidden="true" id="smallBlockHours">
               {this.state.blockInfos.shortText}
            </span>
            <p>
               <span id="largeBlockHours">
                  {this.state.blockInfos.longText}
               </span>{" "}
               <Link to={`/nous-contacter`}>Consultez nos horaires</Link>
            </p>
            {this.props.mPageContact && <Schedule oHours={this.state.hours} />}
         </div>
      )
   }
}

export default BlockHours
